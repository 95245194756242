<script>
export default {
  props: {
    widthBar: {
      type: Number,
      default: 0,
    },
    heightBar: {
      type: Number,
      default: 11,
    },
    radiusBar: {
      type: Number,
      default: 7,
    },
    colorFill: {
      type: String,
      default: "#bd0909",
    },
  },
};
</script>

<template>
  <section class="progressBar" :style="{ height: `${heightBar}px`, 'border-radius': `${radiusBar}px` }">
    <div class="progressBar progressBar--fill" :style="{ width: `${widthBar}%`, 'border-radius': `${radiusBar}px`, background: colorFill }" />
  </section>
</template>

<style lang="scss">
.progressBar {
  width: 100%;
  transition: 1s ease-in-out;
  background: #f0f0f0;
  &--fill {
    max-width: 100%;
    height: 100%;
  }
}
</style>
